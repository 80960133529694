<template>
  <div id='category'>
    <el-card>
      <el-row :gutter="20" class="operate">
        <el-col :span="4">
          <el-cascader
                  v-model="selectPid"
                  :options="options"
                  :props="{ expandTrigger: 'hover', checkStrictly: true }"
                  @change="handleSelectPidChange" placeholder="请选择上级" clearable></el-cascader>
        </el-col>
        <el-col :span="4">
          <el-input v-model="selectName" placeholder="请输入搜索的名称" clearable></el-input>
        </el-col>
        <el-button @click="search" icon="el-icon-search" type="primary"></el-button>
        <el-button @click="openDialog(form, true)" type="primary">添加分类</el-button>
      </el-row>

      <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
        <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
        <el-table-column label="图标" width="100" class-name="avatar">
          <template #default="scope"><el-avatar :size="40" :src="scope.row.icon"></el-avatar></template>
        </el-table-column>
        <el-table-column prop="name" label="分类名称" width="200"></el-table-column>
        <el-table-column prop="desc" label="简介"></el-table-column>
        <el-table-column fixed="right" label="操作" width="210">
          <template #default="scope">
            <el-button @click="queryItem(scope.row.id)" type="primary" size="mini">查看</el-button>
            <el-button @click="openDialog(scope.row, false)" type="warning" size="mini">修改</el-button>
            <el-button @click="deleteItem(scope.row.id)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>

      <!-- 添加弹窗 -->
      <el-dialog @close="closeDialog('addCategoryItem')" title="添加分类" :visible.sync="showDialog" class="add_dialog">
        <el-form :model="form" ref="addCategoryItem" :rules="rules">
          <el-form-item label="上级" prop="name" :label-width="formLabelWidth">
            <el-cascader
                    v-model="form.pid"
                    :options="options"
                    :props="{ expandTrigger: 'hover', checkStrictly: true }"
                    @change="handleChange" placeholder="请选择上级" filterable clearable></el-cascader>
          </el-form-item>
          <el-form-item label="名称" prop="name" :label-width="formLabelWidth">
            <el-col :span="16">
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="图标" :label-width="formLabelWidth">
            <el-upload :action="baseURL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" class="avatar-uploader">
              <img v-if="form.icon" :src="form.icon" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="简介" :label-width="formLabelWidth">
            <el-input v-model="form.desc" type="textarea" :autosize="{minRows: 2, maxRows: 4}" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
            <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{form.status | statusText}}</span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button v-if="isAddFlag" @click="addItem('addCategoryItem')" type="primary">确 定</el-button>
          <el-button v-else @click="editItem('addCategoryItem')" type="primary">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 查看弹窗 -->
      <el-dialog title="查看分类" :visible.sync="checkItem" center class="check_dialog">
        <div class="check_div">
          <div class="check_label">图标：</div>
          <div @click="getSrcList(checkData.icon)" class="check_data">
            <el-image :src="checkData.icon" :preview-src-list="srcList"></el-image>
          </div>
        </div>
        <div class="check_div">
          <div class="check_label">分类名称：</div>
          <div class="check_data">{{checkData.name}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">简介：</div>
          <div class="check_data">{{checkData.desc}}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="checkItem = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {getCateList, getCateCascade, addCateItem, queryCateItem, editCateItem, deleteCateItem} from '@api';

export default {
  data() {
    return {
      params: { // 请求分页参数
        page: 1,
        page_size: 10
      },
      tableData: [], // 表格数据
      loading: true, // 表格加载等待
      showDialog: false, // 是否显示弹窗
      isAddFlag: false, // 是否点击添加用户
      form: { // 添加表单
        icon: '',
        name: '',
        pid: 0,
        desc: '',
        status: 0,
      },
      initForm: {
        icon: '',
        name: '',
        pid: 0,
        desc: '',
        status: 0,
      },
      rules: {
        name: [
          {required: true, message: '请输入分类名称', trigger: 'blur'}
        ],
      },
      formLabelWidth: '120px', // from表单lanel的宽度
      total: 0, // 数据总条数
      checkItem: false, // 查看每条的弹窗
      checkData: {}, // 查询每条时的数据
      selectName: '', // 搜索名称
      selectPid: 0, // 搜索上级
      baseURL: `${this.$store.state.config.baseURL}/upload`, // 上传图片地址
      srcList: [''], // 查看图片
      options: [],
    }
  },
  filters: {
      statusText(status) {
          if (status > 0) {
              return '已审核';
          }
          return '未审核';
      }
  },
  created() {
    this.getData(this.params);
    this.getOptions();
  },
  methods:{
    search() { // 搜索
      const {selectName} = this;
      this.params.name = selectName;
      this.getData(this.params);
    },
    getOptions() {
      getCateCascade().then(res => {
          this.options = res.data;
      })
    },
    addItem(refName) { // 添加分类
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        this.form.status = parseInt(this.form.status); // 状态
        addCateItem(this.form).then(() => {
          this.showDialog = false;
          this.$message.success('添加成功！');
          this.initFormData();
          this.getData(this.params);
          this.getOptions();
        });
      });
    },
    queryItem(id) { // 查询单条数据
      this.checkItem = true;
      this.checkData = {};
      queryCateItem(id).then(res => this.checkData = res.data);
    },
    editItem(refName) { // 修改单条数据
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        const {form, form: {id}} = this;
        const body = form;
        body.status = parseInt(body.status); // 状态
        body.fields = Object.keys(this.initForm);
        editCateItem(id, body).then(() => {
          this.showDialog = false;
          this.$message.success('修改成功！');
          this.getData(this.params);
          this.getOptions();
        });
      });
    },
    deleteItem(id) { // 删除单挑数据
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCateItem(id).then(() => {
          this.$message.success('删除成功!');
          this.getData(this.params);
          this.getOptions();
        });
      }).catch(() => this.$message.info('已取消删除'));
    },
    openDialog(item, addFlag) { // 打开弹窗
      this.form = item;
      this.showDialog = true;
      this.isAddFlag = addFlag;
    },
    closeDialog(refName) { // 关闭弹窗
        this.showDialog = false;
        this.initFormData();
        this.$refs[refName].resetFields();
    },
    cancel() {
        this.showDialog = false;
    },
    getSrcList(src) { // 查看图片
      this.srcList = [];
      this.srcList.push(src);
    },
    handleChange(values) {
      if (values.length > 0) {
          this.form.pid = values[0];
      }
    },
    handleSelectPidChange(values) {
      if (values.length > 0) {
          this.selectPid = values[0]
      }
    },
    handleAvatarSuccess(res, file) { // 上传图片
      this.form.icon = res.data.url;
    },
    beforeAvatarUpload(file) { // 上传图片
      const isImg = file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImg) this.$message.error('上传头像图片只能是 JPG/gif/jpeg/png 格式!');
      if (!isLt2M) this.$message.error('上传头像图片大小不能超过 2MB!');
      return isImg && isLt2M;
    },
    handleCurrentChange(val) { // 获取页数
      this.params.page = val;
      this.getData(this.params);
    },
    handleSizeChange(val) { // 获取每页条数
      this.params.page_size = val;
      this.getData(this.params);
    },
    getData(params) {
      this.loading = true;
      getCateList(params).then(res => {
        this.loading = false;
        this.loading = false;
        const {data, count} = res.data;
        this.total = count;
        this.tableData = data;
      }).catch(() => {
        this.loading = false;
      })
    },
    initFormData() { // 初始化数据
      this.form = this.initForm;
    }
  },
}
</script>

<style lang='less' scoped>

</style>